/* Dropdown menu for quick insert */
.quick-insert-hint {
  position: absolute;
  color: #aaa;
  z-index: 1;
  font-size: 16px;
  font-family: sans-serif;
}

.quick-insert-menu-item {
  padding: 2px 15px 2px 12px;
  display: grid;
  grid-template-columns: auto 1fr;
  grid-template-rows: repeat(2, auto);
  grid-column-gap: 5px;
  align-items: center;
}

.quick-insert-menu-item-icon {
  grid-area: 1 / 1 / 3 / 2;
  font-size: 24px;
  width: 35px;
  line-height: 20px;
  opacity: 0.7;
  text-align: center;
}
.quick-insert-menu-item-title {
  grid-area: 1 / 2 / 2 / 3;
}
.quick-insert-menu-item-description {
  grid-area: 2 / 2 / 3 / 3;
  opacity: 0.5;
  font-size: 85%;
}
