/** Drag handle that appears to the left of blocks */
.froala-drag-handle {
  left: 18px;
  position: absolute;
  cursor: grab;
  z-index: 100;
  transform: translateY(-50%);
}

/** Special properties for SVG of drag handle */
.froala-drag-handle svg {
  height: 1em;
  width: 1em;
  font-size: 1.5em;
  fill: #ddd;
}

/** Drag bar that appears when dragging a block */
.froala-drag-bar {
  position: absolute;
  height: 3px;
  background-color: rgba(94, 128, 255, 0.6);
  z-index: 100;
  pointer-events: none;
}

/** Drag coverup that greys out dragged block */
.froala-drag-coverup {
  position: absolute;
  background-color: rgba(252, 252, 252, 0.5);
  z-index: 100;
  pointer-events: none;
}
