#sb-admin .sidebar .sidebar-brand {
  text-transform: none;
}

#sb-admin .flex-divider {
  border-right: 1px solid #b6b8c4;
}
#sb-admin .icon-container {
  float: left;
  display: inline-block;
}

#sb-admin html,
#sb-admin body {
  font-family: Arial, Helvetica, sans-serif !important;
}

#sb-admin textarea.instruction {
  resize: none;
  max-width: 600px;
}

#sb-admin .highlighted {
  background-color: yellow;
  animation-name: bckanim;
  animation-fill-mode: forwards;
  animation-duration: 3s;
  animation-delay: 0s;
}
@keyframes bckanim {
  0% {
    background-color: yellow;
  }
  100% {
    background-color: transparent;
  }
}

#sb-admin .radio {
  position: relative;
  padding-left: 30px;
  margin-bottom: 0;
  cursor: pointer;
  font-size: 15px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  margin-right: 20px;
}

/* Hide the browser's default radio button */
#sb-admin .radio input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom radio button */
#sb-admin .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background: white;
  border: 1px solid #ccc;
  border-radius: 50%;
}

/* On mouse-over, add a grey background color */
#sb-admin .radio:hover input ~ .checkmark {
  background: #eee;
}

/* When the radio button is checked, add a blue background */
#sb-admin .radio input:checked ~ .checkmark {
  background: white;
  border: 1px solid #ccc;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
#sb-admin .checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
#sb-admin .radio input:checked ~ .checkmark:after {
  display: block;
}

/* Style the indicator (dot/circle) */
#sb-admin .radio .checkmark:after {
  top: 2px;
  left: 2px;
  width: 14px;
  height: 14px;
  border-radius: 50%;
  background-color: #2196f3;
}

#sb-admin table.traing-data-table td {
  border: none;
}

#sb-admin table.traing-data-table tr {
  border-bottom: 1px solid #e3e6f0;
}

#sb-admin .radio-column {
  max-width: 200px;
  width: 200px;
}

#sb-admin .training-table {
  min-height: 70px;
  max-height: 350px;
}

#sb-admin .ck-editor__editable {
  min-height: calc(100vh - 380px);
  max-height: calc(100vh - 380px);
}

#sb-admin #snippet-manualsave-header {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  background: var(--ck-color-toolbar-background);
  border: 1px solid var(--ck-color-toolbar-border);
  padding: 10px;
  border-radius: var(--ck-border-radius);
  border-top: 0;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

#sb-admin #snippet-manualsave-save {
  color: #fff;
  background: hsl(88, 73%, 39%);
  opacity: 0.5;
  padding: 4px 20px;
  display: inline-block;
  border: 0;
  border-radius: 2px;
  font-weight: bold;
  outline: none;
  transition: 300ms ease background;
  /*box-shadow: 0 2px 0 rgb(68, 117, 10);*/
}

#sb-admin #snippet-manualsave-save:active,
#sb-admin #snippet-manualsave-save:focus {
  /*box-shadow: 0 1px 0 rgb(68, 117, 10);*/
}

#sb-admin html,
#sb-admin body {
  height: 100%;
}

#sb-admin html,
#sb-admin body {
  color: #647687;
}

#sb-admin .main-text {
  color: #647687 !important;
}

#sb-admin body {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}

#sb-admin main {
  flex: 1;
}

#sb-admin .black {
  background-color: black;
}

#sb-admin .red {
  background-color: red;
}

#sb-admin .head {
  background-color: aqua;
}

#sb-admin .mr-n3-5,
#sb-admin .mx-n3-5 {
  margin-right: -1.25rem !important;
}

#sb-admin .col-md-1-5 {
  width: 12.5%;
}

#sb-admin .col-md-2-5 {
  width: 20.83333%;
}

#sb-admin .col-md-3-5 {
  width: 29.16667%;
}

#sb-admin .col-md-4-5 {
  width: 37.5%;
}

#sb-admin .col-md-5-5 {
  width: 45.83333%;
}

#sb-admin .col-md-6-5 {
  width: 54.16667%;
}

#sb-admin .col-md-7-5 {
  width: 62.5%;
}

#sb-admin .col-md-8-5 {
  width: 70.83333%;
}

#sb-admin .col-md-9-5 {
  width: 79.16667%;
}

#sb-admin .col-md-10-5 {
  width: 87.5%;
}

#sb-admin .col-md-11-5 {
  width: 95.8333%;
}

#sb-admin .nav-list {
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

#sb-admin .horizontal-nav-list a {
  display: block;
  padding-right: 2rem;
  /*background-color: #dddddd;*/
}

#sb-admin nav,
#sb-admin .bottom-border {
  border-bottom: 1px solid #e3e6f0;
  padding-bottom: 0;
}

#sb-admin .top-border {
  border-top: 1px solid #ccc;
  padding-top: 0;
}

#sb-admin .icon-wrapper {
  position: relative;
  float: left;
}

#sb-admin *.icon-blue {
  color: #0088cc;
}

#sb-admin *.icon-grey {
  color: grey;
}

/*.fas-badged {*/
/*  width:100px;*/
/*  text-align:center;*/
/*  vertical-align:middle;*/
/*}*/

#sb-admin .fas-badged {
  position: relative;
  width: 100px;
  /*text-align:center;*/
  vertical-align: middle;
}

#sb-admin .badge {
  font-size: 0.4em;
  display: block;
  position: absolute;
  top: -0.75em;
  right: 8.1em;
  width: 2em;
  height: 2em;
  line-height: 1.6em;
  border-radius: 50%;
  text-align: center;
  font-family: Arial, Helvetica, sans-serif;
  background: rgba(255, 0, 0, 0.75);
}

#sb-admin .fixed-header {
  position: fixed;
  top: 0;
  height: 3rem;
  left: 0;
  right: 0;
  overflow: hidden;
}

#sb-admin .fixed-footer {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;
  font-size: 85%;
}

#sb-admin .fixed-content {
  position: fixed;
  top: 3.5rem;
  bottom: 3.5rem;
  left: 0;
  right: 0;
  overflow: hidden;
}

#sb-admin .fixed-content-column {
  height: 100%;
  overflow: auto;
}

#sb-admin .slow-spin {
  -webkit-animation: fa-spin 6s infinite linear;
  animation: fa-spin 6s infinite linear;
}

#sb-admin .spin {
  -webkit-animation: fa-spin 3s infinite linear;
  animation: fa-spin 3s infinite linear;
}

#sb-admin .page-gutter {
  position: relative;
  width: 100%;
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}

#sb-admin .heading-1,
#sb-admin .heading-2,
#sb-admin .heading-3,
#sb-admin .heading-4,
#sb-admin .heading-5,
#sb-admin .heading-6 {
  cursor: pointer;
  overflow: hidden;
  text-overflow: ellipsis;
  /*float: left;*/
  white-space: nowrap;
}

#sb-admin .heading-1 {
  font-size: 1.25rem;
}

#sb-admin .heading-2 {
  font-size: 1.15rem;
}

#sb-admin .heading-3 {
  font-size: 1.05rem;
}

#sb-admin .heading-4 {
  font-size: 0.95rem;
}

#sb-admin .heading-5 {
  font-size: 0.85rem;
  margin-left: 4rem;
}

#sb-admin .heading-6 {
  font-size: 0.75rem;
  margin-left: 5rem;
}

#sb-admin .detection-card {
  font-size: 0.75rem;
  border-color: #ccc;
}

#sb-admin .line-clamp {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  max-height: 4.2rem;
}

#sb-admin .txt-primary {
  color: #2c7291;
}

#sb-admin .txt-secondary {
  color: #2f937c;
}

#sb-admin .txt-disabled {
  color: #cccccc;
}

#sb-admin i {
  color: #2c7291;
}

#sb-admin .bgr-primary {
  background-color: #2c7291;
}

#sb-admin .bgr-secondary {
  background-color: #2f937c;
}

#sb-admin .nav-btn {
  max-height: 100%;
}

#sb-admin .active-bottom,
#sb-admin .nav-btn:hover {
  border-bottom: 3px solid #ef8422;
  padding-bottom: 4px;
}

#sb-admin .subnav-btn {
  padding-left: 4px;
  height: 5rem;
  align-content: center;
  border-left: 3px solid #ffffff;
}

#sb-admin .subnav-btn:hover {
  background-color: #e2eff2;
}

#sb-admin .active-background {
  background-color: #2c7291 !important;
}

#sb-admin .active-background a {
  color: white !important;
}

#sb-admin .has-search .form-control {
  padding-left: 2.375rem;
}

#sb-admin .has-search .form-control-feedback {
  position: absolute;
  z-index: 2;
  display: block;
  width: 2.375rem;
  height: 2.375rem;
  line-height: 2.375rem;
  text-align: center;
  pointer-events: none;
  color: #aaa;
}

#sb-admin .pointer {
  cursor: pointer;
}

#sb-admin div.sticky {
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 0;
  z-index: 100;
}

#sb-admin .btn {
  font-size: 0.8rem;
}

#sb-admin .margin {
  margin-top: 0.2rem;
}

#sb-admin .fileUpload {
  position: relative;
  /*overflow: hidden;*/
  /*margin: 10px;*/
}
#sb-admin .fileUpload input.upload {
  position: absolute;
  top: 0;
  right: 0;
  margin: 0;
  padding: 0;
  font-size: 20px;
  cursor: pointer;
  opacity: 0;
  filter: alpha(opacity=0);
}

#sb-admin .rotate-icon-180 {
  transform: rotate(180deg);
}

#sb-admin .rotate-icon-90 {
  transform: rotate(90deg);
}

#sb-admin .transitional-fa {
  -webkit-transition: all 0.2s linear;
  -moz-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  transition: all 0.2s linear;
}

#sb-admin .multiline-tooltip {
  width: 20rem;
  display: block;
}

#sb-admin .table th,
#sb-admin .table td {
  vertical-align: middle;
  padding: 0.5rem 0.75rem;
}

#sb-admin .table th,
#sb-admin .table td {
  vertical-align: middle;
  padding: 0.5rem 0.75rem;
}

:root {
  --amplify-primary-color: #2c7291;
  --amplify-primary-shade: #6a7388;
  --amplify-primary-tint: #2e3f9b;
  --amplify-text-xs: 12px;
  --amplify-text-xxs: 12px;
  --amplify-text-sm: 12px;
}

#sb-admin .nestable-item-name span {
  width: 25px;
  display: inline-flex;
}

#sb-admin .nestable .nestable-list {
  padding: 0 0 0 20px;
}

#sb-admin .boxdetections {
  position: fixed;
  top: 60px;
  right: 0;
  width: 17%;
  z-index: 10;
  padding: 8px 0;
}
#sb-admin .component_wordmark {
  max-height: 26px;
}
#sb-admin .logo_wordmark {
  max-height: 30px;
}

#sb-admin .learnadvisor_wordmark {
  width: 6.2rem;
  margin-right: 1rem;
  margin-top: 6px;
}

#sb-admin .learntransformer_wordmark {
  padding-bottom: 1rem;
}

#sb-admin .detection-list-boxcontent {
  font-size: 1.5rem;
  display: flex;
  column-gap: 1.2rem;
  justify-content: space-between;
}

#sb-admin .modal-select div:first-child {
  background-color: transparent !important;
}

#sb-admin .detection-list-paper {
  /* border-radius: 4px; */
  padding: 5px;
  position: fixed;
  right: 1%;
  top: 85px;
  min-width: 15%;
  /* min-width: 18rem; */
  background-color: #ededed;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.5);
  color: #5a5c69;
}

#sb-admin .detection-list-boxselect {
  padding: 6px 6px;
}

#sb-admin .detection-list-form {
  padding: 0px 6px !important;
  margin: 8px 0 !important;
}

#sb-admin .detection-list-select {
  padding: 0px 8px;
  border-radius: 3px;
  background-color: #fff;
  width: calc(100% - 1rem);
  margin-right: 0.4rem;
  font-size: 0.9rem !important;
}

#sb-admin .detection-list-label {
  height: 15px;
  width: calc(100% - 2rem);
}

#sb-admin .detection-list-button {
  width: 95%;
  background-color: #2f937c !important;
  text-transform: none !important;
  color: #fff !important;
  margin: 0 0 5px 5px !important;
}

#sb-admin .detection-list-radio {
  padding: 0;
  /*margin-right: 10px !important;*/
}

#sb-admin .MuiRadio-colorSecondary.Mui-checked {
  color: #2f937c !important;
}

#sb-admin .detection-list-close {
  text-align: right;
  margin-bottom: 2px;
  margin-right: 3px;
}

#sb-admin .detection-list-badge {
  width: auto;
  min-width: 20px;
  padding: 2px;
}
#sb-admin .detection-list-badge span {
  height: 18px !important;
  min-width: 18px !important;
  padding: 0;
  top: 11%;
  right: 24%;
  border: 0.01px solid #fff;
  font-size: 9px;
}

#sb-admin .analyzer {
  background-color: #ededed;
  padding: 10px;
}

#sb-admin #transformationSummaryContainer div {
  padding: 0.4rem;
}

#sb-admin #transformationSummaryContainer img {
  height: 150px;
}

#sb-admin #transformationSummaryContainer .headline {
  font-size: 140%;
}

#sb-admin #transformationSummaryContainer .subheadline {
  font-size: 120%;
  margin: 2.5rem 0 1rem 0;
}

#sb-admin .nav-icon {
  width: 2.2rem;
  height: 2.2rem;
  cursor: pointer;
  text-align: center;
  border-radius: 50%;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  text-transform: uppercase;
  margin: 0 4px;
}

#sb-admin .avatar {
  background-color: #2f937c;
  color: #fff;
  padding: 0.35rem;
}

#sb-admin .help {
  background-color: #fff;
  color: #2f937c;
  border: 3px solid #2f937c;
  padding: 0.05rem 0.4rem;
  font-weight: bold;
  font-size: 120%;
}

@font-face {
  font-family: "cloud offline";
  src: url("../../fonts/cloud-offline.eot");
  src: url("../../fonts/cloud-offline.eot?#iefix") format("embedded-opentype"),
    url("../../fonts/cloud-offline.woff") format("woff"),
    url("../../fonts/cloud-offline.ttf") format("truetype"),
    url("../../fonts/cloud-offline.svg#cloud-offline") format("svg");
}
#sb-admin [class*="icon-"]:before {
  font-family: "cloud offline";
  position: absolute;
  top: -3px;
  font-size: 190%;
  color: #2f937c;
}
#sb-admin .icon-verification-on-cloud-offline:before {
  content: "\0043";
}

#sb-admin .active-header {
  background-color: #b5d6fd;
}

/* Placeholder text in newly created documents */
#sb-admin .placeholder {
  color: #2c7291;
  font-style: italic;
}

/* Storybook Overrides ------------------------------------------------------ */

#sb-admin .sbdocs .fixed-header {
  position: relative;
}

#sb-admin .sbdocs .fixed-content {
  position: relative;
}

#sb-admin .sbdocs .fixed-footer {
  position: relative;
}

.picker-dialog-bg {
  z-index: 20000 !important;
}

.picker-dialog {
  z-index: 20001 !important;
}
